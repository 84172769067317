var Hash = {
	watcher: function () {
		window.addEventListener("hashchange",Hash.opener)
	},
	opener: function () {
		$(function() {
			if(window.location.hash.length > 1 && window.location.hash.indexOf("line")>0 ) {
				var hash = window.location.hash.split("-");
				var line = hash[0] + "-" + hash[1];
				var item = false;
				if(hash.length>2) {
					item = hash[0] + "-" + hash[1] + "-"+ hash[2];
				}
				/*var link = $("a[href='"+line+"']");

				if(!!link.length) {
					$("[data-level='"+link.closest("[data-level]").data("level")+"'] a").removeClass("active");
					link.addClass("active");
				} else if(line.indexOf("line-0")>0) {
					$(".fileList a").removeClass("active");
				}*/
				$(".panel a").removeClass("active");
				$(".fileList a").removeClass("selected");
				if(item !== false) {
					$("a[href='"+item+"']").addClass("active");
				}
				$(".fileList").find(".line[data-level!=0]").addClass("hidden");
				$(".fileList").find(".line").removeClass("active");
				Hash.showLevel(line);
				Hash.whatAboutTab(item?item:line);
				if(item !== false) {
					$(item).closest("[data-level]").find(".pill a").removeClass("selected")
					$(item).find(".pill a").addClass("selected");
				}
			}
		});
	},
	showLevel: function (id) {
		$(id).removeClass("hidden");
		var level = $(id).data("level")*1;
		var link = $("a[href='"+id+"']");
		link.addClass("selected");
		link.closest(".line").addClass("active");
		if(level > 1) {
			Hash.showLevel("#" + $("a[href='"+id+"']").closest(".line").attr("id"));
		}
	},

	whatAboutTab: function(id) {
		$("#subProjects").foundation("selectTab",$("#"+$(id).closest(".tabs-panel").attr("id")));
	}
};



$.nette.ext("foundation-ext",{
	"init": function() {
		Hash.opener();
		Hash.watcher();
		$(document).foundation();
	},
	"success": function() {
		Hash.opener();
		Hash.watcher();
		$(document).foundation();
		$("[data-reveal]").foundation("close");
	}
});

$.nette.ext({
	before: function (xhr, settings) {
		if (!settings.nette) {
			return;
		}

		var question = settings.nette.el.data('confirm');
		if (question) {
			return confirm(question);
		}
	}
});

$.nette.init();
/*$(function() {
	$(".fileList a:not([download])").click(function(ev) {
		ev.preventDefault();
		var tab = $(this).closest("[data-tabs-content]");
		$(this).closest(".grid-x").find("a").removeClass("active");
		$(this).addClass("active");
		var target= tab.find($(this).attr("href"));
		tab.find("[data-level="+target.data("level")+"]").addClass("hidden");
		target.removeClass("hidden");
	});
	$(".panel a:not(.disabled)").click(function(ev) {
		ev.preventDefault();
		var tab = $(this).closest("[data-tabs-content]");
		var target= tab.find($(this).attr("href"));
		tab.find("[href='"+$(this).attr("href")+"']").closest(".grid-x").find("a").removeClass("active");
		tab.find("[href='"+$(this).attr("href")+"']").addClass("active");

		$(this).closest(".panel").find("a").removeClass("active");
		$(this).addClass("active");

		tab.find("[data-level="+target.data("level")+"]").addClass("hidden");

		target.removeClass("hidden");
	});
});*/